import { css } from '@emotion/react'
import React from 'react'
import { HeadingTertiary } from '../../components/atoms/Heading/HeadingTertiary'
import { LinkBase } from '../../components/atoms/Link/LinkBase'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'
import { styleTable } from '../../components/styles/table'
import { SEO } from '../../components/data/SEO'
import { hoverStyleColorRed } from '../../components/styles/hover'
import { ENGLISH_TITLE } from '../../components/data/English'

const tableData = [
  {
    head: '会社名',
    body: 'CRGホールディングス株式会社',
  },
  {
    head: '設立',
    body: '2013年10月',
  },
  {
    head: '本社所在地',
    body: '〒163-0437<br/>東京都新宿区西新宿2-1-1 新宿三井ビルディング37階<br/>TEL：03-3345-2772<br/>FAX：03-3345-2771',
  },
  {
    head: '資本金',
    body: '4億4,225万円',
  },
  {
    head: '株式市場',
    body: '東京証券取引所 グロース市場（証券コード：7041）',
  },
  {
    head: '売上高（連結）',
    body: '208億（2023年9月期）',
  },
  {
    head: '代表',
    body: '代表取締役社長　<br className="pc:hidden"/>小田 康浩',
  },
  {
    head: 'グループ従業員数',
    body: '1,987名（アルバイト含む）※2023年9月時点',
  },
  {
    head: '事業内容',
    body: 'グループの経営方針策定、経営管理',
  },
]

const Page = () => {
  return (
    <LayoutDefault
      heading="会社概要"
      headingEnglish={ENGLISH_TITLE.Outline}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: '会社概要', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <section className="py-12 pc:px-8 bg-white">
        <div className="mx-auto">
          <img src="/company/img/summary1.png" alt="" />
        </div>
        <div css={styleTable} className="mt-12">
          <table>
            <tbody>
              {tableData.map((row, i) => (
                <tr key={i}>
                  <th dangerouslySetInnerHTML={{ __html: row.head }} />
                  <td dangerouslySetInnerHTML={{ __html: row.body }} />
                </tr>
              ))}
              <tr>
                <th>役員</th>
                <td>
                  <dl>
                    {[
                      { dt: '取締役会長', dd: '井上　弘' },
                      { dt: '代表取締役社長', dd: '小田　康浩' },
                      { dt: '社外取締役', dd: '半田　純也' },
                      { dt: '社外取締役', dd: '吉原　直輔' },
                      { dt: '常勤監査役', dd: '岡野　務' },
                      { dt: '社外監査役', dd: '長井　亮輔' },
                      { dt: '社外監査役', dd: '島　正彦' },
                      { dt: '執行役員', dd: '三並　史典' },
                      { dt: '執行役員', dd: '酒井　大' },
                    ].map((l, i) => (
                      <div
                        key={i}
                        className="flex first:pt-0 pt-4 pc:pt-0 pc:flex-row flex-col pc:items-center"
                      >
                        <dt className="pc:w-[180px] flex-shrink-0">{l.dt}</dt>
                        <dd>{l.dd}</dd>
                      </div>
                    ))}
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="mt-12 bg-white">
        <div className="py-12 pc:px-8">
          <HeadingTertiary label="会社案内パンフレット" />
          <div className="mt-6 flex pc:flex-row flex-col">
            <div className="pc:w-[403px]">
              <img src="/common/img/report.png" alt="" />
            </div>
            <div className="pc:ml-8 mt-4 pc:mt-8">
              <LinkBase href="/common/pdf/corporatereport.pdf">
                <div className="flex items-center">
                  <span className="underline text-md font-medium">
                    2024年9月2日発行
                  </span>
                  <span
                    css={css`
                      width: 31px;
                      height: 15px;
                      margin-left: 16px;
                      background-image: url('/common/img/ico_pdf.png');
                      background-size: contain;
                    `}
                  ></span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_2842_28947"
                      maskUnits="userSpaceOnUse"
                      x="10"
                      y="11"
                      width="10"
                      height="8"
                    >
                      <rect
                        x="10"
                        y="11"
                        width="10"
                        height="8"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_2842_28947)">
                      <path
                        d="M20 15L11.75 17.5981L11.75 12.4019L20 15Z"
                        fill="#CC050B"
                      />
                    </g>
                  </svg>
                </div>
              </LinkBase>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-12 bg-white">
        <div className="py-12 pc:px-8">
          <HeadingTertiary label="アクセス" />
          <div className="mt-6">
            <div
              css={css`
                & > iframe {
                  width: 100%;
                  height: 400px;
                  @media screen and (max-width: 1199px) {
                    height: 300px;
                  }
                  @media screen and (max-width: 519px) {
                    height: 41.3vw;
                  }
                }
              `}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.394586484708!2d139.69171051525896!3d35.69190638019187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cc25a41bfb9%3A0xb83fbcc6287ed6b0!2zQ1JH44Ob44O844Or44OH44Kj44Oz44Kw44K55qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1664437454799!5m2!1sja!2sjp"
                width="600"
                height="450"
                title="CRGHD"
                // style="border:0;"
                // allowfullscreen=""
                loading="lazy"
                // referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="mt-4 pc:text-right">
              <LinkBase
                css={hoverStyleColorRed}
                href="https://goo.gl/maps/tXLZn2ngRUC2"
              >
                <span className="inline-flex items-center">
                  <span className="pr-1">大きな地図を見る</span>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.5 1.00001C6.5 0.723861 6.72386 0.5 7.00001 0.5H11.5001C11.7762 0.5 12.0001 0.723861 12.0001 1.00001V5.50007C12.0001 5.77621 11.7762 6.00007 11.5001 6.00007C11.2239 6.00007 11.0001 5.77621 11.0001 5.50007V1.50001H7.00001C6.72386 1.50001 6.5 1.27615 6.5 1.00001Z"
                      fill="#CC050B"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 3.00001C0 2.72386 0.223861 2.5 0.500007 2.5H4.50006C4.77621 2.5 5.00007 2.72386 5.00007 3.00001C5.00007 3.27615 4.77621 3.50001 4.50006 3.50001H1.00001V11.5001H9.00012V8.00007C9.00012 7.72393 9.22398 7.50007 9.50013 7.50007C9.77627 7.50007 10.0001 7.72393 10.0001 8.00007V12.0001C10.0001 12.2763 9.77627 12.5001 9.50013 12.5001H0.500007C0.223861 12.5001 0 12.2763 0 12.0001V3.00001Z"
                      fill="#CC050B"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.3536 1.14645C11.5489 1.34171 11.5489 1.6583 11.3536 1.85357L5.85357 7.35364C5.6583 7.5489 5.34171 7.5489 5.14645 7.35364C4.95118 7.15838 4.95118 6.84179 5.14645 6.64652L10.6465 1.14645C10.8418 0.951184 11.1584 0.951184 11.3536 1.14645Z"
                      fill="#CC050B"
                    />
                  </svg>
                </span>
              </LinkBase>
            </div>
            <p className="font-bold mt-8 pc:mt-[-10px] text-lg">
              CRGホールディングス株式会社
            </p>
            <p className="text-md mt-4 pc:mt-6 leading-tight">
              〒163-0437
              <br />
              東京都新宿区西新宿2-1-1 新宿三井ビルディング37階
              <br />
              TEL：03-3345-2772 <br />
              FAX：03-3345-2771
              <br />
            </p>
          </div>
        </div>
      </section>
    </LayoutDefault>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="会社概要" url="/company/summary" />
